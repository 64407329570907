//import {AppVersion} from "@awesome-cordova-plugins/app-version";
//import {Device} from "@capacitor/device";
import {reactive, toRefs} from "vue";
import {Router} from "vue-router";
import {AppData, Division, User} from "@/common/interfaces";


const appData = reactive<AppData>({
    booted: false,
    versionNumber: "",
    versionCode: "",
    uuid: "",
    languageCode: "",
    oneSignalUserId: ""/*,
    user: null,
    division: null,
    token: null*/
});

let router:Router;

export const useApp = () => {
    const setAppData = async () => {
        const versionNumber = "";//await AppVersion.getVersionNumber();
        const versionCode = "";//await AppVersion.getVersionCode();
        //const deviceId = await Device.getId();
        const languageCode = "it";//await Device.getLanguageCode();
        appData.versionNumber = "" + versionNumber;
        appData.versionCode = "" + versionCode;
        //appData.uuid = "" + deviceId.uuid;
    };

    const setBooted = (booted: boolean) => {
        appData.booted = booted;
    }

    const setLanguageCode = (languageCode: string) => {
        appData.languageCode = languageCode;
    }

    const setOneSignalUserId = (oneSignalUserId:any) => {
        appData.oneSignalUserId = oneSignalUserId;
    }
    const getOneSignalUserId = () => {
        return appData.oneSignalUserId;
    }

    const setRouter = (r:Router) => {
        return router = r;
    }
/*
    const setUser = (user:User)=>{
        appData.user = user;
    }
    const getUser = (): User|null => {
        return appData.user;
    };

    const setDivision = (division:Division)=>{
        appData.division = division;
    }
    const getDivision = (): Division|null => {
        return appData.division;
    };

    const setToken = (token:string)=>{
        appData.token = token;
    }
    const getToken = (): string|null => {
        return appData.token;
    };
*/
    return {
        setAppData,
        setBooted,
        setLanguageCode,
        setOneSignalUserId,
        getOneSignalUserId,
        setRouter,
/*
        setUser,
        getUser,
        setDivision,
        getDivision,
        setToken,
        getToken,
*/
        ...toRefs(appData),
    }
};
