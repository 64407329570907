import UtilsService from "@/services/utils";

import ApiService from "@/services/api";

import StorageService from "@/services/storage";

import {useApp} from "@/modules/appManager";

//import {SplashScreen} from "@capacitor/splash-screen";

import {Keyboard} from '@capacitor/keyboard';

//import OneSignal from "onesignal-cordova-plugin";

import {useEvents} from "@/modules/eventsManager";
import {Emitter,EventType} from "mitt";
import {Router} from "vue-router";

export default class CoreService {

    static EVT_UNAUTHORIZED = "EVT_UNAUTHORIZED";

    apiService: ApiService;

    storageService: StorageService;

    utilsService: UtilsService;

    constructor() {

        this.apiService = ApiService.getInstance();

        this.storageService = StorageService.getInstance();

        this.utilsService = new UtilsService();

    }

    async bootstrap(router:Router) {

        const {setBooted} = useApp();

        await this.apiService.init();
        await this.storageService.init();

        const {setAppData,setRouter} = useApp();
        setRouter(router);
        await setAppData();

        // Event listeners
        const {emitter} = useEvents();

        emitter.on(CoreService.EVT_UNAUTHORIZED, async() => {

            await router.replace({path: '/login'})
        });

//        // Give some time to prepare the first page
//        setTimeout(async () => {
//            await SplashScreen.hide();
//        }, 250);

        // Boot completed
        setBooted(true);
    }

}
