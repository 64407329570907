import {nextTick} from 'vue';
import {createI18n, I18n} from 'vue-i18n';

export const useLocalization = () => {

    const SUPPORT_LOCALES = ['it', 'en'];

    const i18n = createI18n({
        locale: 'it'
    });

    async function loadLocaleMessages(i18n: I18n, locale: string) {
        const messages = await import(
            /* webpackChunkName: "locale-[request]" */ `.././locales/${locale}.json`
            )
        i18n.global.setLocaleMessage(locale, messages.default);

        return nextTick();
    }

    loadLocaleMessages(i18n, i18n.global.locale);

    const $t = i18n.global.t;

    return {
        SUPPORT_LOCALES,
        i18n,
        loadLocaleMessages,
        $t
    }

}