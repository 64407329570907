import {Network} from "@capacitor/network";

import {ref} from "vue";

import axios, {AxiosInstance} from "axios";

import UtilsService from "@/services/utils";

import StorageService from "@/services/storage";

import {useEvents} from "@/modules/eventsManager";
import {Emitter, EventType} from "mitt";

import CoreService from "@/services/core";

//import {FileTransfer, FileTransferObject} from "@awesome-cordova-plugins/file-transfer";

//axios.defaults.withCredentials = true;


export default class ApiService {

    private static instance: ApiService;

    public static TOKEN_PLACEHOLDER = "TOKEN_PLACEHOLDER";

    async replaceToken(payload: any) {

        if ( this.utilsService.objectHasOwnProperty(payload["headers"], "Authorization") ) {

            const token = await this.storageService.getToken();

            if (token.length > 0) {

                payload["headers"]["Authorization"] = payload["headers"]["Authorization"].replaceAll(ApiService.TOKEN_PLACEHOLDER, token);
            }else {
                throw new Error("Api Manager - token not found!");
            }

            console.log(payload);

            return payload;
        }
    }

    public static errors = {
        connection: 'connection',
        server: 'server',
        timeout: 'timeout',
    };

    private utilsService: any;

    connected = ref(false);

    axiosInstance: AxiosInstance;

    storageService: StorageService;

    emitter: Emitter<Record<EventType, any>>;

    private constructor() {

        this.utilsService = new UtilsService();

        this.axiosInstance = axios.create({
            baseURL: process.env.VUE_APP_BASE_URL + process.env.VUE_APP_API_PATH ,
            /*validateStatus: function (status) {
                debugger;
                return status == 200 || status == 401;
            }*/
        });

        this.storageService = StorageService.getInstance();


        const {emitter} = useEvents();
        this.emitter = emitter;

        /*DEBUG*/
        /*
        this.axiosInstance.interceptors.request.use(request => {
            console.log('Starting Request', JSON.stringify(request, null, 2));
            return request;
        });

        this.axiosInstance.interceptors.response.use(response => {
            console.log('Response:', JSON.stringify(response, null, 2));
            return response;
        });
        */
    }

    public static getInstance() {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }
        return ApiService.instance;
    }

    async init() {
        const connectionStatus = await Network.getStatus();
        this.connected.value = connectionStatus.connected;

        console.log("ApiService - Network status: connected: ", this.connected.value);

        // Listener for connection changes
        Network.addListener("networkStatusChange", (connectionStatus) => {
            this.connected.value = connectionStatus.connected;
            console.log("ApiService - Network status changed: connected: ", this.connected.value);
        });
    }

    /**
     * @return object
     */
    _headers(addAuth = true) {
        const headers: any = {
            "accept": "text/plain",
            "Content-Type": "application/json"
        };
        if (addAuth) {
            headers["Authorization"] = "Bearer " + ApiService.TOKEN_PLACEHOLDER;
        }
        return headers;
    }

    async _call(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.axiosInstance.request(payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Check connection and call api and error management
     * @param payload
     * @param queued
     * @return {*}
     */
    async callApi(payload: any, queued = false) {
        if (queued) {
            await this.storageService.insertApiQueueRow(payload);
        } else {
            const _this = this;
            await this.replaceToken(payload);
            return this.checkConnection()
                .then(() => {
                    return _this._call(payload);
                })
                .catch((error: any) => {
                    if (error.response && error.response.status == 401 &&
                        (payload.url == "/auth/login" || payload.url == "/auth/password-change" || payload.url == "/auth/forgot-password")
                    ) {
                        //-- case of login failed ex: wrong username or password
                        return Promise.resolve(error);
                    } else {
                        return _this.catchServerError(error, payload);
                    }

                });
        }
    }

    async checkConnection() {

        console.log("ApiService - checkConnection: ", this.connected.value);

        if (this.connected.value) {
            return Promise.resolve();
        } else {
            return Promise.reject(ApiService.errors.connection);
        }
    }

    catchServerError(error: any, payload: any) {
        //debugger;
        if (error.response && error.response.status == 401) {
            //-- if not authorized redirect to login
            this.emitter.emit(CoreService.EVT_UNAUTHORIZED);

            return Promise.resolve(error);

        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);

            return Promise.reject(error);
        }


    }

    /**
     * AUTH
     */

    /**
     *
     */
    async authTest() {
        const payload = {
            method: "get",
            url: "/auth/test",
            params: {},
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param email
     * @param password
     */
    async login(email: string, password: string) {
        const payload = {
            method: "post",
            url: "/auth/login",
            params: {'email': email, 'password': password},
            headers: this._headers(false)
        }
        return await this.callApi(payload);
    }

    /**
     *
     */
    async logout() {
        const payload = {
            method: "post",
            url: "/auth/logout",
            params: {},
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     *
     */
    async revokeTokens() {
        const payload = {
            method: "post",
            url: "/auth/revoke-tokens",
            params: {},
            headers: this._headers(false)
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param email
     */
    async forgotPassword(email: string) {
        const payload = {
            method: "post",
            url: "/auth/forgot-password",
            params: {'email': email},
            //-- no Auth
            headers: this._headers(false)
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param password
     * @param new_password
     * @param new_password_confirmation
     */
    async passwordChange(password: string, new_password: string, new_password_confirmation: string) {
        const payload = {
            method: "post",
            url: "/auth/password-change",
            params: {
                'password': password,
                'new_password': new_password,
                'new_password_confirmation': new_password_confirmation
            },
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     * PPE CATEGORIES
     */

    /**
     *
     */
    async getCategories() {
        const payload = {
            method: "get",
            url: "/ppe/categories",
            params: {},
            headers: this._headers()
        }

        if (this.connected.value) {
            return await this.callApi(payload);
        }else{
            return await this.storageService.getSyncCategories();
        }
    }

    /**
     *
     * @param category_id
     * @param division_id
     */
    async getCategory(category_id: number, division_id: string) {
        const payload = {
            method: "get",
            url: `/ppe/categories/${category_id}`,
            params: {'division_id': division_id},
            headers: this._headers()
        }

        if (this.connected.value) {
            return await this.callApi(payload);
        }else{
            return await this.storageService.getSyncCategory(category_id, division_id);
        }
    }

    /**
     * PPE ITEMS
     */

    /**
     *
     * @param division_id
     */
    async getItems(division_id: string) {
        const payload = {
            method: "get",
            url: "/ppe/items",
            params: {'division_id': division_id},
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param division_id
     * @param search
     */
    async searchItems(division_id: number, search: string) {
        const payload = {
            method: "get",
            url: "/ppe/items",
            params: {'division_id': division_id, 'search': search},
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param data
     */
    async createItem(data: object) {
        const payload = {
            method: "post",
            url: "/ppe/items",
            params: {},
            data: data,
            headers: {
                "accept": "*/*",
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + ApiService.TOKEN_PLACEHOLDER
            }
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param item_id
     */
    async getItem(item_id: number) {
        const payload = {
            method: "get",
            url: `/ppe/items/${item_id}`,
            params: {},
            headers: this._headers()
        }

        if (this.connected.value) {
            return await this.callApi(payload);
        }else{
            return await this.storageService.getSyncItem(item_id);
        }
    }

    /**
     *
     * @param item_id
     * @param data
     */
    async setItem(item_id: string, data: object) {
        const payload = {
            method: "post",
            url: `/ppe/items/${item_id}`,
            params: {},
            data: data,
            headers: {
                "accept": "*/*",
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + ApiService.TOKEN_PLACEHOLDER
            }
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param item_id
     */
    async removeItem(item_id: number) {
        const payload = {
            method: "delete",
            url: `/ppe/items/${item_id}`,
            params: {},
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     * PPE MAINTENANCES
     */

    /**
     *
     * @param division_id
     * @param include_item
     * @param page
     */
    async getMaintenances(division_id: string, include_item: string, page: string) {
        const payload = {
            method: "get",
            url: "/ppe/maintenances",
            params: {'division_id': division_id, 'include_item': include_item, 'page': page},
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param maintenance_id
     */
    async getMaintenance(maintenance_id: number) {
        const payload = {
            method: "get",
            url: `/ppe/maintenances/${maintenance_id}`,
            params: {},
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     * PPE MISC
     */

    /**
     *
     */
    async getSync() {
        const payload = {
            method: "get",
            url: `/ppe/sync-get`,
            params: {},
            headers: this._headers()
        }
        return await this.callApi(payload);
    }

    /**
     *
     * @param category_id
     * @param division_id
     */
    async getChecklistModels(category_id: number, division_id: number) {
        const payload = {
            method: "get",
            url: `/ppe/checklist_models/${category_id}/${division_id}`,
            params: {},
            headers: this._headers()
        }
        if (this.connected.value) {
            return await this.callApi(payload);
        }else{
            debugger;
            return await this.storageService.getSyncChecklistModels(category_id, division_id);
        }
    }

    /**
     *
     * @param item_id
     * @param data
     * @param queued
     */
    async setChecklist(item_id: string, data: object, queued = false) {
        const payload = {
            method: "post",
            url: `/ppe/checklists/${item_id}`,
            params: {},
            data: data,
            headers: {
                "accept": "*/*",
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + ApiService.TOKEN_PLACEHOLDER
            }
        }
        return await this.callApi(payload, queued);
    }

    /**
     *
     * @param item_id
     * @param data
     * @param queued
     */
    async setExternalChecklist(item_id: string, data: object, queued = false) {
        const payload = {
            method: "post",
            url: `/ppe/external_checklists/${item_id}`,
            params: {},
            data: data,
            headers: {
                "accept": "*/*",
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + ApiService.TOKEN_PLACEHOLDER
            }
        }
        return await this.callApi(payload, queued);
    }

    async getToken(username: string, password: string) {

        const payload = {
            method: "post",
            url: "/jwt-auth/v1/token",
            params: {
                username: username,
                password: password
            },
            headers: {
                "accept": "application/json, text/plain, */*",
                "Content-Type": "application/json",
            }
        }

        return await this.callApi(payload);
    }

    async getUser() {

        const token = await this.storageService.getToken();

        const payload = {
            method: "get",
            url: "/wp/v2/users/me",
            params: {},
            headers: {
                "accept": "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }

        return await this.callApi(payload);
    }

    async getSettings() {

        const token = await this.storageService.getToken();

        const payload = {
            method: "get",
            url: "/ppe/settings",
            params: {},
            headers: {
                "accept": "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }

        return await this.callApi(payload);
    }

    async getMaintenanceStates() {

        const token = await this.storageService.getToken();

        const payload = {
            method: "get",
            url: "/ppe/maintenance_states",
            params: {},
            headers: {
                "accept": "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }

        return await this.callApi(payload);
    }
}
