import {Storage, Drivers} from '@ionic/storage';

import UtilsService from "@/services/utils";
import {Category, Item, User} from "@/common/interfaces";

export default class StorageService {

    private static instance: StorageService;

    private storage: Storage;

    private utilsService: any;

    private constructor() {

        this.storage = new Storage({
            name: '__mydb',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        });

        this.utilsService = new UtilsService();
    }

    public static getInstance() {
        if (!StorageService.instance) {
            StorageService.instance = new StorageService();
        }
        return StorageService.instance;
    }

    public async init() {
        await this.getStorage().create();
    }

    private getStorage() {
        return this.storage;
    }

    async setUserData(userData: User) {

        await this.getStorage().set('userData', userData);
    }

    async getUserData():Promise<User> {

        return await this.getStorage().get('userData') as User;
    }

    async setToken(token: string) {

        await this.getStorage().set('token', token);
    }

    async getToken():Promise<string> {

        return await this.getStorage().get('token');
    }

    async isUserLoggedIn() {

        return await this.getToken() != null && await this.getUserData()!=null;
    }

    async setDivision(division: number) {

        await this.getStorage().set('division', division);
    }

    async getDivision():Promise<number> {

        return await this.getStorage().get('division');
    }

    async insertApiQueueRow(payload: any) {
        const queue = await this.getStorage().get('verificationsQueue') || [];
        //payload.data = JSON.stringify(Object.fromEntries(payload.data));
        queue.push(payload);
        await this.getStorage().set('verificationsQueue', queue);
    }

    async deleteApiQueueRow(rowId: number) {
        //const queue = JSON.parse(await this.getStorage().get('verificationsQueue')) || [];
        //await this.getStorage().set('verificationsQueue', JSON.stringify(queue));
    }

    async getApiQueue() {
        return await this.getStorage().get('verificationsQueue');
    }

    /**
     *
     * @param data
     */
    async setSync(sync:any){
        await this.getStorage().set('sync', sync);
    }

    /**
     *
     */
    async getSync(){

        return await this.getStorage().get('sync');
    }

    /**
     *
     */
    async getSyncCategories(){

        const sync = await this.getSync()

        const res = {data: {
                status: sync.status,
                lang: sync.lang,
                categories: ( sync.categories.map(({id,order,name}:Category) => ({id,order,name})) )
            }};

        return res;
    }

    async getSyncCategory(category_id: number, division_id: string){

        const sync = await this.getSync()

        const settings = await this.getStorage().get('settings');

        const cat = sync.categories.find((x:Category) => x.id === category_id);

        if( cat != undefined && this.utilsService.objectHasOwnProperty(cat.divisions, division_id) ){

            return {data: {
                    status: sync.status,
                    lang: sync.lang,
                    settings: settings,
                    category: {
                        id: cat.id,
                        name: cat.name,
                        items: cat.divisions[division_id]['items']
                    }
                }};
        }

        return {data: {
                status: false,
                lang: sync.lang,
                message: 'sync category not found'
        }};
    }

    async getSyncItem(item_id: number){

        //-- TODO: domandare da dove prendere division_id ???
        const division_id = await this.getDivision();

        const sync = await this.getSync()

        for(let i=0; i<sync.categories.length; i++){

            const cat:any = sync.categories[i];

            if( this.utilsService.objectHasOwnProperty(cat['divisions'], division_id) ){

                const item = cat['divisions'][division_id]['items'].find((x:Item) => x.id === item_id);

                if( item!=undefined ) {

                    item.category = {
                        id: cat.id,
                        name: cat.name
                    }

                    return {data: {
                            status: sync.status,
                            lang: sync.lang,
                            item: item
                        }};
                }
            }

        }

        return null;
    }

    async getSyncChecklistModels(category_id:number, division_id:number){

        const sync = await this.getSync()

        const maintenanceStates = await this.getMaintenanceStates();

        for(let i=0; i<sync.categories.length; i++){

            const cat:any = sync.categories[i];

            if( cat.id == category_id ) {

                const checkListModels:[] = cat['checklist_models'];

                const model = checkListModels.find((x:any) => x.division_id === 1);

                if( model != undefined) {

                    return {
                        data: {
                            status: sync.status,
                            lang: sync.lang,
                            checklist_model: {
                                //id: cat['checklist_models']['id'],
                                category: {
                                    id: cat.id,
                                    name: cat.name
                                },
                                questions: model['questions']
                            },
                            maintenance_states: maintenanceStates
                        }
                    };
                }
            }
        }

        return null;
    }

    //---------------------------------------------------------

    /**
     *
     * @param data
     */
    async setSettings(data:any){
        await this.getStorage().set('settings', data.settings);
    }

    /**
     *
     */
    async getSettings(){

        return await this.getStorage().get('settings');
    }

    //---------------------------------------------------------

    /**
     *
     * @param data
     */
    async setMaintenanceStates(data:any){
        await this.getStorage().set('maintenanceStates', data.maintenance_states);
    }

    /**
     *
     */
    async getMaintenanceStates(){

        return await this.getStorage().get('maintenanceStates');
    }

    //---------------------------------------------------------

    /**
     * Clear the entire key value store. WARNING: HOT!
     */
    async logOut():Promise<void> {

        return await this.getStorage().clear();
    }


}
