
import {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    IonTitle,
    //IonNote,
//  IonIcon,
    IonContent,
    IonMenu,
    IonLabel,
    IonItem,
    IonList,
    IonMenuToggle,
    //IonText,
    IonHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonToolbar
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';

import {useApp} from "@/modules/appManager";

import {homeOutline, mapOutline, logOutOutline, documentTextOutline} from "ionicons/icons";

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
        IonSplitPane,
        IonTitle,
        //IonNote,
//    IonIcon,
        IonContent,
        IonMenu,
        IonLabel,
        IonItem,
        IonList,
        IonMenuToggle,
        //IonText,
        IonHeader,
        IonGrid,
        IonRow,
        IonCol,
        IonToolbar
    },
    setup() {

        const userData = ref('');

        const ionMenu = ref<any>();

        //const {storageService} = useStorage();

        const {versionNumber, versionCode} = useApp();

        const appVersion = "ver " + versionNumber.value + " code " + versionCode.value;

        return {
            userData,
            ionMenu,
            //storageService,
            homeOutline,
            mapOutline,
            logOutOutline,
            documentTextOutline,
            appVersion
        }
    },
    async mounted() {

        try {

            //this.ionMenu.$el.open();

            //this.userData = await this.storageService.getUserData();

        } catch (error) {
            console.error(error);
        }
    },
    methods: {

    }
});


