import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import StorageService from "@/services/storage";

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: '/home'
    },
    {
        path: '/home',
        component: () => import("@/views/home/Home.vue")
    },
    {
        path: '/login',
        component: () => import("@/views/login/Login.vue")
    },
    {
        path: '/password-recover',
        component: () => import("@/views/login/PasswordRecover.vue")
    },
    {
        path: '/password-change',
        component: () => import("@/views/login/PasswordChange.vue")
    },
    {
        path: '/division',
        component: () => import("@/views/login/Division.vue")
    },
    {
        path: '/notifications',
        component: () => import("@/views/notifications/Notifications.vue")
    },
    {
        path: '/categories',
        component: () => import("@/views/catalog/Categories.vue")
    },
    {
        path: '/category/:id',
        component: () => import("@/views/catalog/Category.vue")
    },
    {
        path: '/search',
        component: () => import("@/views/catalog/Search.vue")
    },
    {
        path: '/product/:id',
        component: () => import("@/views/catalog/Product.vue")
    },
    {
        path: '/product/:id/edit',
        component: () => import("@/views/catalog/ProductEdit.vue")
    },
    {
        path: '/product/new',
        component: () => import("@/views/catalog/ProductEdit.vue")
    },
    {
        path: '/verification/:id',
        component: () => import("@/views/verification/Verification.vue")
    },
    {
        path: '/product/:id/verification/new',
        component: () => import("@/views/verification/VerificationNew.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const storageService = StorageService.getInstance();

router.beforeEach(async (to, from, next) => {
    // Check valid token before routing, only if booted

    if ( to.path != "/login" && to.path != "/password-recover" ) {

        const isUserLoggedIn = await storageService.isUserLoggedIn();

        if (!isUserLoggedIn) {
            console.log("ROUTE NOT ALLOWED TO GUEST -> REDIRECT TO LOGIN");
            next({path: "/login"})
        } else {
            next();
        }
    }else{
        next();
    }
});

export default router
