import {Capacitor} from "@capacitor/core";
//import {Filesystem, Directory, Encoding} from "@capacitor/filesystem";
import {StatusBar, Style} from "@capacitor/status-bar";

import {alertController, loadingController, LoadingOptions/*, actionSheetController*/} from "@ionic/vue";

import {DateTime} from 'luxon';
//import {ListData} from "@/services/api";

//import {useLocalization} from "@/modules/localizationManager";
//import {BackgroundColorOptions, StyleOptions} from "@capacitor/status-bar/dist/esm/definitions";

//const {translator} = useLocalization();

export default class UtilsService {

    constructor() {
        //
    }

    noop() {
        return;
    }

    // Platform
    isAndroid() {
        return Capacitor.getPlatform() === "android";
    }

    isIos() {
        return Capacitor.getPlatform() === "ios";
    }

    // Object
    isObject(obj: any) {
        return typeof obj === 'object' && obj !== null
    }

    objectHasOwnProperty(obj: object, property: any) {
        return Object.prototype.hasOwnProperty.call(obj, property)
    }

    mergeObjects(obj1: object, obj2: object): any {
        return {...obj1, ...obj2};
    }

    isSameObject(obj1: object, obj2: object) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    copyWithoutReference(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    // Undefined
    isUndefined(value: any) {
        return typeof value === "undefined";
    }

    // String
    isString(string: any) {
        return typeof string === "string";
    }

    // Number
    isNumber(number: any) {
        return typeof number === "number";
    }

    // Function
    isFunction(f: any) {
        return typeof f === "function";
    }

    // Parser
    parseInt(value: any, radix = 10) {
        if (this.isString(value)) {
            let output = parseInt(value, radix);
            if (isNaN(output)) {
                output = 0;
            }
            return output;
        }
        if (this.isNumber(value)) {
            return parseInt("" + value, radix);
        }

        return 0;
    }

    parseFloat(value: any, digits: any = null) {
        let output = 0;
        if (this.isString(value)) {
            output = parseFloat(value);
            if (isNaN(output)) {
                output = 0;
            }
        } else if (this.isNumber(value)) {
            output = parseFloat("" + value);
        }

        if (digits !== null) {
            output = parseFloat("" + output.toFixed(2));
        }

        return output;
    }

    round(value: any) {
        let output = Math.round(value);
        if (isNaN(output)) {
            output = 0;
        }
        return output;
    }

    /**
     *
     * @param dateAsISOString
     * @return string | IfInvalid<'Invalid DateTime'>
     */
    formatDate(dateAsISOString: string) {
        return DateTime.fromISO(dateAsISOString).toFormat('dd/MM/yyyy');
    }

    /*getCurrentYeay() {
        return DateTime.now().year;
    }*/

    // Deferred
    deferred() {
        let resolve, reject;
        const notify = this.noop, promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });

        return {
            promise,
            resolve,
            reject,
            notify
        }
    }

    // Dom
    calculateElementInnerDimensions(element: any) {
        let width = 0, height = 0;
        if (element) {
            const style = element.currentStyle || window.getComputedStyle(element);
            const marginX = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
            const paddingX = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
            const borderX = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
            width = element.clientWidth - marginX - paddingX - borderX;

            const marginY = parseFloat(style.marginTop) + parseFloat(style.marginBottom);
            const paddingY = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
            const borderY = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
            height = element.clientHeight - marginY - paddingY - borderY;
        }
        return {width: width, height: height};
    }

    /**
     *
     * @param element
     */
    /*
    renderInterval(element: any) {
        const _this = this;
        return new Promise((resolve, reject) => {
            if (element) {
                let elementDimensions = _this.calculateElementInnerDimensions(element);
                if (elementDimensions.width > 0 && elementDimensions.height > 0) {
                    resolve(elementDimensions);
                } else {
                    const renderInterval = setInterval(() => {
                        elementDimensions = _this.calculateElementInnerDimensions(element);
                        if (elementDimensions.width > 0 && elementDimensions.height > 0) {
                            clearInterval(renderInterval);
                            resolve(elementDimensions);
                        }
                    }, 50);
                }
            } else {
                reject("Invalid element");
            }
        });
    }
    */

    // Interface
    async setStatusBarStyle(style: string) {
        console.log("Changing StatusBarStyle: ", style);
        let backgroundColorOptions = null;
        let styleOptions = null;
        switch (style) {
            case "default-light":
                backgroundColorOptions = {color: "#f6f8f9"};
                styleOptions = {style: Style.Light};
                break;
            case "default-dark":
            default:
                backgroundColorOptions = {color: "#4268f9"};
                styleOptions = {style: Style.Dark};
                break;
        }

        if (this.isAndroid()) {
            await StatusBar.setBackgroundColor(backgroundColorOptions);
        }
        await StatusBar.setStyle(styleOptions);
    }

    /**
     *
     * @param options
     * @return Promise<HTMLIonLoadingElement>
     */
    async createLoadingIndicator(options: LoadingOptions = {}): Promise<HTMLIonLoadingElement> {
        if (!Object.hasOwn(options, 'spinner')) {
            options.spinner = 'circles';
        }
        return loadingController.create(options);
    }

    async showAlert(header = "", message = "", ok = "", cancel = "", translationParams = {}, cssClass = "") {

        const options: any = {
            cssClass: cssClass,
            header: header,
            message: message,
        };

        return new Promise((resolve) => {
            if (ok.length > 0) {
                options.buttons = [
                    {
                        text: ok,
                        handler: () => {
                            resolve(true);
                        },
                    }
                ]
            }

            if (cancel.length > 0) {
                if (!options.buttons) {
                    options.buttons = [];
                }
                options.buttons.push({
                    text: cancel,
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        resolve(false);
                    },
                });
            }

            alertController.create(options)
                .then((alert) => {
                    alert.present();
                });
        });
    }

    modalInitialBreakpoint() {
        return this.isIos() ? 0.93 : 0.99;
    }

    /*
        async presentActionSheet(item:ListData, openHandler:any, shareHandler:any){

            console.log('click presentActionSheet!!!');

            const buttons = [{
                text: 'Apri',
                handler: () => {
                    console.log('click Apri');
                    openHandler(item);
                }
            }];

            if(item.forwardable || item.forwardable === undefined) {
                buttons.push({
                    text: 'Condividi',
                    handler: () => {
                        console.log('click Condividi');
                        shareHandler(item);
                    }
                });
            }

            const actionSheet = await actionSheetController.create({
                header: ('Azione per '+ item.name),
                buttons: buttons
            });

            await actionSheet.present();


        }

        delay(milliseconds:number) {
            return new Promise(resolve => {
                setTimeout(resolve, milliseconds);
            });
        }
     */

    validateEmail(email: string) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    validatePassword(password: string) {

        if (password.length < 12) {
            return "deve essere lunga almeno 12 caratteri";
        } else if (!password.match(/[a-z]/)) {
            return "deve contenere almeno una lettera minuscola";
        } else if (!password.match(/[A-Z]/)) {
            return "deve contenere almeno una lettera maiuscola";
        } else if (!password.match(/[-_*$@#%!?]/)) {
            return "deve contenere almeno un simbolo -_*$@#%!?";
        }

        return true;
    }

    getImageAbsolutePath(relativePath: string|undefined){

        return process.env.VUE_APP_BASE_URL + process.env.VUE_APP_IMAGE_PATH + relativePath;
    }
}
